import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Чистка BioClean
			</title>
			<meta name={"description"} content={"Делаем каждое пространство безупречным"} />
			<meta property={"og:title"} content={"Чистка BioClean"} />
			<meta property={"og:description"} content={"Делаем каждое пространство безупречным"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/unique-handdrawn-floral-modern-shapes.png?v=2023-04-27T21:48:23.623Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/666ff497fdcae00021e37443/images/1-4.jpg?v=2024-06-17T08:59:33.040Z"
						display="block"
						max-width="100%"
						width="350px"
						srcSet="https://smartuploads.quarkly.io/666ff497fdcae00021e37443/images/1-4.jpg?v=2024-06-17T08%3A59%3A33.040Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ff497fdcae00021e37443/images/1-4.jpg?v=2024-06-17T08%3A59%3A33.040Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ff497fdcae00021e37443/images/1-4.jpg?v=2024-06-17T08%3A59%3A33.040Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ff497fdcae00021e37443/images/1-4.jpg?v=2024-06-17T08%3A59%3A33.040Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ff497fdcae00021e37443/images/1-4.jpg?v=2024-06-17T08%3A59%3A33.040Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ff497fdcae00021e37443/images/1-4.jpg?v=2024-06-17T08%3A59%3A33.040Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ff497fdcae00021e37443/images/1-4.jpg?v=2024-06-17T08%3A59%3A33.040Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Услуги
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						Наш комплексный набор услуг по уборке создан для того, чтобы обеспечить удовлетворение ваших потребностей в уборке с высоким профессионализмом и вниманием к деталям, независимо от того, владелец вы дома или бизнесмен.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Решения для детальной очистки
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					В Чистка BioClean мы не просто убираем – мы превращаем пространство. Спектр наших услуг охватывает от ежедневного обслуживания до специализированных уборок, выполняемых нашей командой преданных своему делу профессионалов по уборке.
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					В Чистка BioClean мы не просто убираем – мы превращаем пространство. Спектр наших услуг охватывает от ежедневного обслуживания до специализированных уборок, выполняемых нашей командой преданных своему делу профессионалов по уборке.
				</Text>
				<Text
					as="h1"
					margin="3rem 0px 0px 0px"
					font="--headline3"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Различные предложения по уборке
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Услуги по уборке домов
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Регулярное обслуживание: Регулярные визиты для выполнения таких задач, как удаление пыли, пылесосения и уборка.
							<br />
							Глубокая уборка: Тщательная уборка труднодоступных и запущенных мест, идеальная для сезонной уборки или подготовки к особому событию.{" "}
							<br />
							Уборка при заезде/выезде:  Целевые услуги для обеспечения девственности имущества для новых арендаторов или после выезда.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Коммерческие программы очистки
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Корпоративная уборка офиса: Настраиваемые графики уборки для поддержки безупречной и профессиональной бизнес-среды.{" "}
							<br />
							Уборка розничной торговли и гостеприимства:  Индивидуальные решения для магазинов, гостиниц и ресторанов, которые сохраняют простор уютным и гигиеническим.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Специальные услуги по уборке
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Экологическая уборка: Использование экологически чистых чистящих средств, которые являются эффективными, но бережными для окружающей среды. Послестроительная уборка: подробная очистка от пыли и мусора после ремонта или строительства, чтобы подготовить новое помещение к использованию.{" "}
							<br />
							Уборка событий: Услуги по уборке до и после событий для корпоративных мероприятий, свадеб и других крупных мероприятий.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Исследуйте весь спектр наших возможностей
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					В этом обзоре приведена поверхность того, что может предоставить Чистка BioClean. Каждая наша услуга поддерживается стремлением к экологически чистым практикам и удовлетворенности клиентов. Мы понимаем, что каждое пространство уникально, и мы стремимся настроить наши услуги в соответствии с вашими конкретными потребностями. Обратитесь к нам, чтобы узнать, как мы можем помочь вам поддерживать окружающую среду.
				</Text>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});